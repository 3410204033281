import { RTEMatchInfo, RTEMatchInfoForResults } from '@/contexts/rte/types';
import { MatchProps } from '@/modules/results/types';
import { MatchInfo } from '@/modules/ticker/types';

function toSnakeCase(key: string): string {
	return key.replace(/([A-Z])/g, '_$1').toLowerCase();
}

export function getMatchesInSnakeCase(matches: RTEMatchInfo[]): RTEMatchInfoForResults[] {
	return matches.map((match) => {
		const snakeCaseMatch: Partial<RTEMatchInfoForResults> = {};

		Object.keys(match).forEach((key) => {
			const snakeKey = toSnakeCase(key);
			(snakeCaseMatch as Record<string, any>)[snakeKey] = (match as Record<string, any>)[key];
		});

		return snakeCaseMatch as RTEMatchInfoForResults;
	});
}

export const shouldUseRTEHook = (matches: MatchInfo[]): boolean => {
	if (process.env.NEXT_PUBLIC_RTE_ENABLED !== 'true') {
		return false;
	}

	return matches.some((match) => {
		return match.matchStatus !== 4 && !match.matchCompleted;
	});
};

export const shouldUseRTEHookResults = (matches: MatchProps[]): boolean => {
	if (process.env.NEXT_PUBLIC_RTE_ENABLED !== 'true') {
		return false;
	}

	return matches.some((match) => {
		return match.match_status !== 4 && !match.match_completed;
	});
};
