/* eslint-disable @next/next/no-img-element */
'use client';

import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';

import { isPROD } from '@/components/RenderAd/constants';
import { getZoneImage } from '@/utils/ads/getZoneImage';
import { triggerURL } from '@/utils/ads/triggerURL';
import { PlacementInterface } from '@/utils/helpers/types';

import { AD_SIZES_HASH, AdType } from './types';

export interface RenderAdProps {
	className?: string;
	containerClassname?: string;
	height?: number;
	ipAddress?: string;
	numberOfDifferentZoneImages?: number;
	pageId: number;
	place: number;
	setNumberOfDifferentZoneImages?: React.Dispatch<React.SetStateAction<number | undefined>>;
	zoneId: string;
	width?: number;
}

interface AdInfo {
	image?: PlacementInterface;
	pageId: number;
	place: number;
	viewableURLCalled: boolean;
	eligibleURLCalled: boolean;
}

const RenderAd: React.FC<RenderAdProps> = ({
	className,
	containerClassname,
	height,
	ipAddress,
	numberOfDifferentZoneImages,
	pageId,
	place,
	setNumberOfDifferentZoneImages,
	zoneId,
	width
}) => {
	const adRef = useRef<HTMLDivElement>(null);

	const placementId = `${zoneId}_${pageId}_${place}`;
	const [adInfo, setAdInfo] = useState<AdInfo>({
		pageId: numberOfDifferentZoneImages ? +pageId + Math.floor(+place / numberOfDifferentZoneImages) : +pageId,
		place: numberOfDifferentZoneImages ? +place % numberOfDifferentZoneImages : +place,
		viewableURLCalled: false,
		eligibleURLCalled: false
	});
	const adSizeInfo = AD_SIZES_HASH[zoneId as AdType];
	const actualWidth = width ? width : adSizeInfo.width;
	const actualHeight = height ? height : adSizeInfo.height;
	const adSize = actualWidth && actualHeight ? `${actualWidth}x${actualHeight}` : '';

	useEffect(() => {
		if (isPROD) {
			const fetchImage = async (): Promise<boolean> => {
				const currentUrl = location.protocol + '//' + location.host + location.pathname;
				const screenWidth = screen.width;
				const screenHeight = screen.height;
				const screenPixelRatio = window.devicePixelRatio;
				const userAgent = navigator.userAgent;

				const response = await getZoneImage({
					ipAddress: ipAddress,
					pageId: adInfo.pageId,
					place: adInfo.place,
					refferingURL: currentUrl,
					screenHeight: screenHeight,
					screenPixelRatio: screenPixelRatio,
					screenWidth: screenWidth,
					size: adSize,
					userAgent: userAgent,
					zoneId: zoneId
				});

				if (response.status === 200 && response.placement) {
					const fetchedImage: PlacementInterface = response.placement;
					setAdInfo((prevAdInfo) => ({
						...prevAdInfo,
						image: fetchedImage,
						place: prevAdInfo.place + 1,
						viewableURLCalled: false,
						eligibleURLCalled: false
					}));
				} else {
					if (setNumberOfDifferentZoneImages) {
						setNumberOfDifferentZoneImages(adInfo.place);
					}

					const response2 = await getZoneImage({
						// generating new pageId and fetching from place 0
						ipAddress: ipAddress,
						pageId: adInfo.pageId + 1,
						place: 0,
						refferingURL: currentUrl,
						screenHeight: screenHeight,
						screenPixelRatio: screenPixelRatio,
						screenWidth: screenWidth,
						size: adSize,
						userAgent: userAgent,
						zoneId: zoneId
					});

					if (response2.status === 200 && response2.placement) {
						const fetchedImage2: PlacementInterface = response2.placement;
						setAdInfo((prevAdInfo) => ({
							...prevAdInfo,
							image: fetchedImage2,
							pageId: prevAdInfo.pageId + 1,
							place: 1,
							viewableURLCalled: false,
							eligibleURLCalled: false
						}));
					} else {
						return false; // ad zone image fetch error - could be that AdButler API is down or the device browser has an advertisement blocking system
					}
				}

				return true; // ad zone image fetched successfully
			};

			if (adInfo.image && adInfo.image.refresh_time) {
				const interval = setInterval(async () => {
					const fetchedSuccessfuly = await fetchImage();
					if (!fetchedSuccessfuly) {
						clearInterval(interval);
					}
				}, +adInfo.image.refresh_time * 1000);

				return () => clearInterval(interval);
			} else {
				fetchImage();
			}
		}
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [adInfo.image]);

	useEffect(() => {
		if (isPROD) {
			const observer = new IntersectionObserver(
				(entries) => {
					entries.forEach((entry) => {
						if (!adInfo.eligibleURLCalled) {
							if (adInfo.image?.eligible_url) {
								triggerURL(adInfo.image.eligible_url);
							}
							setAdInfo((prevAdInfo) => ({ ...prevAdInfo, eligibleURLCalled: true }));
						}

						// Check if 50% or more of the target element is visible
						if (entry.intersectionRatio >= 0.5) {
							if (!adInfo.viewableURLCalled) {
								if (adInfo.image?.viewable_url) {
									triggerURL(adInfo.image.viewable_url);
								}
								setAdInfo((prevAdInfo) => ({ ...prevAdInfo, viewableURLCalled: true }));
							}
						}
					});
				},
				{ threshold: 0.5 } // Set threshold to 0.5 (50% visibility)
			);

			const adElement = adRef.current;
			if (adElement) {
				observer.observe(adElement);
			}

			return () => {
				if (adElement) {
					observer.unobserve(adElement);
				}
			};
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [adInfo.image, adInfo.viewableURLCalled, adInfo.eligibleURLCalled]);

	if (isPROD && !adInfo.image) {
		return null;
	}

	const aspectRatio = actualWidth / actualHeight;

	const classes = clsx(
		'relative h-0 w-full overflow-hidden rounded-md sm:rounded-xl',
		{
			'border border-solid bg-gray-400 text-white': !isPROD
		},
		className
	);

	return (
		<div className={containerClassname} style={{ maxWidth: `${actualWidth}px` }}>
			<div id={placementId} ref={adRef} style={{ paddingTop: `calc(100% / ${aspectRatio})` }} className={classes}>
				{isPROD && adInfo.image ? (
					<>
						{adInfo.image.is_redirectable ? (
							<a
								href={adInfo.image.redirect_url}
								target={adInfo.image.target}
								rel="nofollow"
								className="absolute inset-0 size-full object-cover"
							>
								<img src={adInfo.image.image_url} alt={adInfo.image.alt_text} />
							</a>
						) : (
							<img src={adInfo.image.image_url} alt={adInfo.image.alt_text} className="absolute inset-0 size-full object-cover" />
						)}
						{adInfo.image.accupixel_url && <img src={adInfo.image.accupixel_url} alt="" />}
					</>
				) : (
					<div className="absolute inset-0 flex size-full items-center justify-center object-cover">ADVERTISEMENT</div>
				)}
			</div>
		</div>
	);
};

export default RenderAd;
